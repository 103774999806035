import { GuestUser } from '../domain/entities/GuestUser';
import { BrowserStorage } from '@estee/elc-universal-utils';

export class GuestRepository {
    private _guestUser: GuestUser;

    public fetchGuestUser = () => {
        return BrowserStorage.getItem('guestEmail', 'sessionStorage');
    };

    public saveGuestUser = () => {
        BrowserStorage.setItem('guestEmail', this._guestUser.email, 'sessionStorage');
    };

    public getGuestUser = async () => {
        const init = async () => {
            const isGuestUser = this.fetchGuestUser();

            return new GuestUser(isGuestUser || '');
        };

        if (!this._guestUser) {
            this._guestUser = await init();
        }

        return this._guestUser;
    };
}
