import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { ConfigStore } from '../../service-setup/ConfigStore';
import { encode } from 'base-64';
import { v4 } from 'uuid';
import { emit, events, query, serviceQueries } from '@estee/elc-service-bus';
import { AuthSessionRepository } from '../repositories/AuthSessionRepository';
import { SignInRepository } from '../repositories/SignInRepository';
import { CARE_STRATEGY_CANCEL, CARE_STRATEGY_LOGO } from '../constants/constants';
import { IAuthRequestStateData } from '../domain/entities/IAuthRequestStateData';
import { generateCareDomain } from '../utils/care';

export type IStrategyConfig = {
    [key: string]: string;
};

export interface IRequestCareAuthPayload {
    context?: string;
    strategy?: string;
    redirectUrl?: string;
    strategies: {
        [name: string]: IStrategyConfig;
    };
}

export class RequestCareAuthUseCase {
    private readonly config: IRequestCareAuthPayload;
    private readonly configStore: ConfigStore;
    private readonly authSessionRepository: AuthSessionRepository;
    private readonly signInRepository: SignInRepository;

    constructor(config: IRequestCareAuthPayload) {
        this.config = { ...config };
        this.configStore = diContainer.get(serviceNames.configStore);
        this.authSessionRepository = diContainer.get(serviceNames.authSessionRepository);
        this.signInRepository = diContainer.get(serviceNames.signInRepository);
    }

    public execute = async () => {
        emit(events.AUTH_ATTEMPT);

        // mark busy
        this.signInRepository.setIsBusy(true);

        // generate new state
        const state = v4();
        this.authSessionRepository.setAuthRequestState(state);

        const data: IAuthRequestStateData = {
            redirectUrl: this.config.redirectUrl || ''
        };
        this.authSessionRepository.setAuthRequestStateData(data);

        const url = await this.generateUrl(state);

        window.location.assign(url);
    };

    private generateUrl = async (state: string): Promise<string> => {
        const serviceConfig = this.configStore.config;
        const { clientId, env, businessUnitId } = serviceConfig;
        const { context, strategy, strategies } = this.config;

        const encodeStrategyParams = (params: object) => encode(JSON.stringify(params));
        const encodeQueryParams = (params: object) =>
            Object.keys(params)
                .filter((key: keyof typeof params) => !!params[key])
                .map<string>(
                    (key: keyof typeof params) => `${key}=${encodeURIComponent(params[key])}`
                )
                .join('&');

        // don't exceed 63 chars per subdomain
        const url = `${generateCareDomain(window.location.origin, env)}/v0/oauth/authorize`;

        const strategyParams: { [key: string]: unknown } = {
            [CARE_STRATEGY_LOGO]: {
                url: window.location.origin
            },
            [CARE_STRATEGY_CANCEL]: {
                url: window.location.toString()
            },
            ...(strategies || {})
        };

        const queryParams = {
            state,
            context,
            user_intent: strategy,
            response_type: 'token',
            client_id: clientId,
            redirect_uri: await query(serviceQueries.CARE_HANDLER_URL),
            business_unit: businessUnitId,
            sp: encodeStrategyParams(strategyParams)
        };

        return `${url}?${encodeQueryParams(queryParams)}`;
    };
}
