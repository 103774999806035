import { GuestRepository } from '../repositories/GuestRepository';
import { emit, events, query, serviceQueries } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import {
    CARE_QUERY_PARAM_NAME_STRATEGY,
    CARE_STRATEGY_CANCEL,
    CARE_STRATEGY_GUEST
} from '../constants/constants';
import { v4 } from 'uuid';
import { AuthSessionRepository } from '../repositories/AuthSessionRepository';

export class ContinueAsGuestUseCase {
    public execute = async () => {
        const guestRepository: GuestRepository = diContainer.get(serviceNames.guestRepository);
        const authSessionRepository: AuthSessionRepository = diContainer.get(
            serviceNames.authSessionRepository
        );

        const guestUser = await guestRepository.getGuestUser();
        const email = guestUser.email;

        const careHandlerUrl = new URL(await query(serviceQueries.CARE_HANDLER_URL));
        careHandlerUrl.searchParams.set(CARE_QUERY_PARAM_NAME_STRATEGY, CARE_STRATEGY_GUEST);

        const route = await query(serviceQueries.GET_CURRENT_ROUTE);
        const urlRedirectOnSuccess = route.queryParams.urlRedirectOnSuccess;

        const state = v4();
        authSessionRepository.setGuestAuthRequestState(state);

        emit(events.REQUEST_CARE_AUTH, {
            // @todo - needs refactor on checkout and cart services to specify the context
            context: 'pre_checkout',
            strategy: CARE_STRATEGY_GUEST,
            redirectUrl: urlRedirectOnSuccess,
            strategies: {
                [CARE_STRATEGY_GUEST]: {
                    email,
                    url: careHandlerUrl.toString(),
                    payload: {
                        // set the GUEST strategy to respond with GET instead of POST
                        $$handler: 'get',
                        // eslint-disable-next-line no-template-curly-in-string
                        userEmail: '${email}',
                        // eslint-disable-next-line no-template-curly-in-string
                        subscribe: '${optin_email_promotions}',
                        // also add state
                        state
                    }
                },
                [CARE_STRATEGY_CANCEL]: {
                    url: window.location.toString()
                }
            }
        });
    };
}
