import { ViewController } from '@estee/elc-service';
import { SignInUseCase } from '../use-cases/SignInUseCase';
import { action, computed } from 'mobx';
import { SignInRepository } from '../repositories/SignInRepository';
import { IFormStore } from '../domain/entities/IFormStore';
import { ISignInFormValues } from '../domain/entities/ISignInFormValues';

export interface ISignInFormViewControllerConfig {
    signInRepository: SignInRepository;
}

export class SignInFormViewController extends ViewController {
    private signInRepository: SignInRepository;
    private formStore: IFormStore<ISignInFormValues>;

    constructor(config: ISignInFormViewControllerConfig) {
        super();

        this.signInRepository = config.signInRepository;
    }

    @action
    public loaded = async () => {
        this.isLoading = true;

        this.formStore = await this.signInRepository.ensureFormStore();

        this.isLoading = false;
    };

    @action
    public onSubmit = () => {
        if (!this.formStore) {
            return;
        }

        const useCase = new SignInUseCase();
        void useCase.execute();
    };

    @computed
    public get data() {
        return {
            formStore: this.signInRepository.formStore,
            busy: this.signInRepository.isBusy
        };
    }

    @computed
    public get isValid() {
        return this.formStore ? this.formStore.isValid : false;
    }
}
