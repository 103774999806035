import { GuestRepository } from '../repositories/GuestRepository';
import { query, serviceQueries } from '@estee/elc-service-bus';

export class LoadGuestUserUseCase {
    private guestRepository: GuestRepository;

    constructor(guestRepository: GuestRepository) {
        this.guestRepository = guestRepository;
    }

    public execute = async () => {
        const guestUser = await this.guestRepository.getGuestUser();
        const routeStatus = await query(serviceQueries.GET_CURRENT_ROUTE);
        const isRedirected = routeStatus.queryParams.urlRedirectOnSuccess;

        if (isRedirected) {
            guestUser.signUpRequired = false;
        }
    };
}
