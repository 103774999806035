export const AUTHENTICATION_SOURCES = Object.freeze({
    SIGN_IN: 'sign-in',
    SIGN_UP: 'sign-up',
    FORGOT_PASSWORD: 'forgot-password',
    REFRESH_AUTH_TOKEN: 'refresh-auth-token',
    CARE: 'care'
});

export const TOKEN_TYPE = Object.freeze({
    GUEST: 'guest',
    USER: 'user'
});

export const GUEST_COOKIE_NAMES = Object.freeze({
    AUTH_TOKEN: 'elc-auth-token-guest',
    AUTH_TYPE: 'elc-auth-token-type-guest'
});

export const COOKIES_NAMES = Object.freeze({
    AUTH_TOKEN: 'elc-auth-token',
    AUTH_TYPE: 'elc-auth-token-type',
    CARE_AUTH_TOKEN: 'elc-care-auth-token',
    CARE_AUTH_TYPE: 'elc-care-auth-token-type'
});

export const FAIL_LOGIN_ATTEMPT = Object.freeze({
    BAD_CREDENTIALS: 'bad_credentials',
    CAPTCHA: 'bad_credentials_captcha',
    WARNING: 'bad_credentials_warning',
    FIRST_LOCK: 'locked_user_1st',
    SECOND_LOCK: 'locked_user_2nd'
});

export const OPT_IN_SOURCE = Object.freeze({
    SIGN_IN: 'sign-in'
});

export const CARE_SIGNIN_STATE_NAME = 'elc-care-signin-state';
export const CARE_SIGNIN_DATA_NAME = 'elc-care-signin-data';
export const CARE_GUEST_SIGNIN_STATE_NAME = 'elc-care-guest-signin-state';

export const AUTHORIZATION_HEADER_NAME = 'AuthorizationToken';

export const CARE_QUERY_PARAM_NAME_STRATEGY = 's';
export const CARE_STRATEGY_SIGN_IN = 'signin';
export const CARE_STRATEGY_SIGN_UP = 'signup';
export const CARE_STRATEGY_GUEST = 'guest';
export const CARE_STRATEGY_LOGO = 'logo';
export const CARE_STRATEGY_CANCEL = 'cancel';

export enum AuthMode {
    CARE = 'care'
}

/*
 This is a list of urls that will NEVER use the new Authorizer
 */
export const BLACKLISTED_AUTH_URLS = [
    '/stardust-prodcat-product-',
    '/stardust-buid-configurations-',
    '/stardust-fe-logging-'
];
