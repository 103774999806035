import {
    commandProvider,
    events,
    on,
    queryProvider,
    serviceCommands,
    serviceQueries
} from '@estee/elc-service-bus';
import { SignOutUseCase } from '../internal/use-cases/SignOutUseCase';
import { ClearAuthSessionUseCase } from '../internal/use-cases/ClearAuthSessionUseCase';
import {
    IAuthSession,
    UpdateAuthSessionUseCase
} from '../internal/use-cases/UpdateAuthSessionUseCase';
import { SetEmailUseCase } from '../internal/use-cases/SetEmailUseCase';
import { reaction } from 'mobx';
import { AuthSessionRepository } from '../internal/repositories/AuthSessionRepository';
import { AuthSession } from '../internal/domain/entities/AuthSession';
import { GuestRepository } from '../internal/repositories/GuestRepository';
import {
    IRequestCareAuthPayload,
    RequestCareAuthUseCase
} from '../internal/use-cases/RequestCareAuthUseCase';
import { encode } from 'base-64';

export interface IServiceBusControllerConfig {
    authSessionRepository: AuthSessionRepository;
    authSession: AuthSession;
    careAuthSession: AuthSession;
    guestRepository: GuestRepository;
    businessUnitId: string;
}

export class ServiceBusController {
    private readonly authSessionRepository: AuthSessionRepository;
    private readonly authSession: AuthSession;
    private readonly careAuthSession: AuthSession;
    private readonly guestRepository: GuestRepository;
    private readonly businessUnitId: string;

    constructor(config: IServiceBusControllerConfig) {
        this.authSessionRepository = config.authSessionRepository;
        this.authSession = config.authSession;
        this.careAuthSession = config.careAuthSession;
        this.guestRepository = config.guestRepository;
        this.businessUnitId = config.businessUnitId;
        reaction(() => this.authSession.token, this.tokenChanged);
        reaction(() => this.careAuthSession.token, this.careTokenChanged);
    }

    @queryProvider(serviceQueries.IS_AUTHENTICATED)
    public isAuthenticated = () => {
        return !!this.careAuthSession.isAuthenticated;
    };

    @queryProvider(serviceQueries.GUEST_EMAIL)
    public guestEmail = async () => {
        const guestUser = await this.guestRepository.getGuestUser();

        return guestUser.email;
    };

    @queryProvider(serviceQueries.GET_LEGACY_GUEST_AUTH_TOKEN)
    public getLegacyGuestAuthToken = async () => {
        const token = await this.authSessionRepository.getLegacyAuthToken();

        return token;
    };

    @queryProvider(serviceQueries.GET_GUEST_AUTH_TOKEN)
    public getGuestAuthToken = async () => {
        return encode(`${this.businessUnitId}:${window.location.origin}`);
    };

    @commandProvider(serviceCommands.SET_SIGN_IN_EMAIL)
    public setFormEmail = async ({ email }: { email: string }) => {
        const setEmailUseCase = new SetEmailUseCase(email);
        await setEmailUseCase.execute();
    };

    @commandProvider(serviceCommands.AUTH_UPDATE_DATA)
    public updateAccessToken = (payload: IAuthSession) => {
        const useCase = new UpdateAuthSessionUseCase(payload);
        useCase.execute();
    };

    @on(events.UNAUTHORIZED_REQUEST)
    public unauthorizedRequest = () => {
        // @todo - re-enable this if/once it makes sense
        // turned off since we will have 403s while we switch to the new service versions
        // eslint-disable-next-line
        if (false) {
            const useCase = new ClearAuthSessionUseCase(this.authSessionRepository);
            void useCase.execute();
        }
    };

    @on(events.SIGNED_OUT)
    public signedOut = () => {
        const useCase = new SignOutUseCase();
        void useCase.execute();
    };

    @on(events.REQUEST_CARE_AUTH)
    public onCareSignIn = async (config: IRequestCareAuthPayload) => {
        const useCase = new RequestCareAuthUseCase(config);
        await useCase.execute();
    };

    public tokenChanged = () => {
        this.authSessionRepository.storeToken();
    };

    public careTokenChanged = () => {
        this.authSessionRepository.storeCareToken();
    };
}
