import { AuthSessionRepository } from '../repositories/AuthSessionRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { ClearAuthSessionUseCase } from './ClearAuthSessionUseCase';

export class SignOutUseCase {
    private authSessionRepository: AuthSessionRepository;

    constructor() {
        this.authSessionRepository = diContainer.get(serviceNames.authSessionRepository);
    }

    public execute = async () => {
        const clearAuthSessionUseCase = new ClearAuthSessionUseCase(this.authSessionRepository);
        await clearAuthSessionUseCase.execute();
    };
}
