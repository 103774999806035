import { Service, ServiceInitializer } from '@estee/elc-service';
import { InitializeTokenUseCase } from '../internal/use-cases/InitializeTokenUseCase';
import {
    CARE_LANDING,
    SIGN_IN_FORM,
    SIGN_IN_NAV_LINK,
    SIGN_IN_SCREEN,
    SIGN_UP_FORM,
    SIGN_UP_SCREEN
} from '@estee/elc-service-view-names';

const { name, version } = __serviceInfo__;

export class SessionManagementService extends Service {
    constructor(serviceIntializer: ServiceInitializer) {
        super(name, version, serviceIntializer);

        this.registerView(SIGN_IN_SCREEN, () =>
            import(
                /* webpackChunkName: 'SingleSignOn' */
                '../exported/single-sign-on/SingleSignOn'
            )
        );

        this.registerView(SIGN_IN_NAV_LINK, () =>
            import(
                /* webpackChunkName: 'SignInNavLink' */
                '../exported/nav-link/SignInNavLink'
            )
        );

        this.registerView(SIGN_IN_FORM, () =>
            import(
                /* webpackChunkName: 'SignInForm' */
                '../exported/sign-in-form/SignInForm'
            )
        );

        this.registerView(SIGN_UP_SCREEN, () =>
            import(
                /* webpackChunkName: 'SignUpScreen' */
                '../exported/sign-up-screen/SignUpScreen'
            )
        );

        this.registerView(SIGN_UP_FORM, () =>
            import(
                /* webpackChunkName: 'SignUpForm' */
                '../exported/sign-up-form/SignUpForm'
            )
        );

        this.registerView(CARE_LANDING, () =>
            import(
                /* webpackChunkName: 'CARe_Landing' */
                '../exported/care-landing/CareLanding'
            )
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async start(drupalConfig: any) {
        await super.start(drupalConfig);

        //normal flow
        const useCase = new InitializeTokenUseCase();
        await useCase.execute();
    }
}
