import { action, computed, observable } from 'mobx';

export class GuestUser {
    @observable public signUpRequired: boolean = true;
    @observable private _email: string = '';

    constructor(email: string) {
        this._email = email;
    }

    @computed
    get email() {
        return this._email;
    }

    @action
    public setEmail = (email: string) => {
        this._email = email;
    };
}
