import { SignUpFormStore } from '../stores/SignUpFormStore';
import { SignUpUseCase } from '../use-cases/SignUpUseCase';
import { action, computed } from 'mobx';

export interface ISignUpFormViewControllerConfig {
    signUpFormStore: SignUpFormStore;
}

export class SignUpFormViewController {
    private readonly signUpFormStore: SignUpFormStore;

    constructor(config: ISignUpFormViewControllerConfig) {
        this.signUpFormStore = config.signUpFormStore;
    }

    @computed
    public get data() {
        return {
            signUpFormStore: this.signUpFormStore
        };
    }

    @action
    public onSubmit = () => {
        const useCase = new SignUpUseCase();
        void useCase.execute();
    };
}
