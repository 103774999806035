import { action, computed, observable } from 'mobx';
import { IFormStore } from '../domain/entities/IFormStore';
import { ISignInFormValues } from '../domain/entities/ISignInFormValues';
import { createSignInFormStore } from '../domain/entities/SignInFormStoreFactory';

export class SignInRepository {
    @observable formStore: IFormStore<ISignInFormValues> | null;
    @observable private busy = false;

    @action
    public ensureFormStore = async (): Promise<IFormStore<ISignInFormValues>> => {
        if (!this.formStore) {
            this.formStore = await createSignInFormStore();
        }

        return this.formStore;
    };

    @computed
    public get isBusy() {
        return this.busy;
    }

    @action
    public setIsBusy(busy: boolean) {
        this.busy = busy;
    }

    @computed
    public get email() {
        return this.formStore ? this.formStore.values.email : '';
    }

    @action
    public setEmail(email: string) {
        if (this.formStore) {
            this.formStore.values.email = email;
        }
    }

    @action
    public clearStore() {
        if (this.formStore) {
            this.formStore.clearStore();
        }
    }
}
