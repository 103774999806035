import { action, computed } from 'mobx';
import { ContinueAsGuestUseCase } from '../use-cases/ContinueAsGuestUseCase';
import { GuestRepository } from '../repositories/GuestRepository';
import { ViewController } from '@estee/elc-service';

export interface IGuestFormViewControllerConfig {
    guestRepository: GuestRepository;
}

export class GuestFormViewController extends ViewController {
    private guestRepository: GuestRepository;

    constructor(config: IGuestFormViewControllerConfig) {
        super();

        this.guestRepository = config.guestRepository;
    }

    @action
    public loaded = async () => {
        this.isLoading = true;

        await this.guestRepository.getGuestUser();

        this.isLoading = false;
    };

    @computed
    public get data() {
        return {};
    }

    @action
    public continueAsGuest = async () => {
        const continueAsGuestUseCase = new ContinueAsGuestUseCase();
        await continueAsGuestUseCase.execute();
    };
}
