/* eslint-disable @typescript-eslint/no-explicit-any */
import { observable, action } from 'mobx';

export class ConfigStore {
    @observable public config: any;
    @observable
    public breakpoints: Theme.IBreakpoints = {
        desktop: 768,
        largeScreen: 1024,
        extraLargeScreen: 1200
    };

    constructor(config: any) {
        this.updateConfig(config);
    }

    @action
    public updateConfig(config: any) {
        this.config = { ...config };
    }
}
