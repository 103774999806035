import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { AuthSessionRepository } from '../repositories/AuthSessionRepository';
import { emit, events } from '@estee/elc-service-bus';
import { AuthSession } from '../domain/entities/AuthSession';

export class InitializeTokenUseCase {
    private authSessionRepository: AuthSessionRepository;
    private careAuthSession: AuthSession;

    constructor() {
        this.authSessionRepository = diContainer.get(serviceNames.authSessionRepository);
        this.careAuthSession = diContainer.get(serviceNames.careAuthSession);
    }

    public execute = async () => {
        await this.authSessionRepository.ensureAuthSession();

        if (this.careAuthSession.isAuthenticated) {
            void emit(events.AUTH_SUCCEDED, this.careAuthSession.authSourceData);
        }
    };
}
