import { AuthSessionRepository } from '../repositories/AuthSessionRepository';
import { AuthSession } from '../domain/entities/AuthSession';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { TOKEN_TYPE } from '../constants/constants';

export class SetGuestAuthSessionUseCase {
    private authSessionRepository: AuthSessionRepository;
    private authSession: AuthSession;
    private careAuthSession: AuthSession;

    constructor() {
        this.authSessionRepository = diContainer.get(serviceNames.authSessionRepository);
        this.authSession = diContainer.get(serviceNames.authSession);
        this.careAuthSession = diContainer.get(serviceNames.careAuthSession);
    }

    public execute = async () => {
        const response = await this.authSessionRepository.getGuestToken();
        const authSession = new AuthSession(
            response.access_token,
            TOKEN_TYPE.GUEST,
            response.expires_in
        );
        this.authSession.updateAuthSession(authSession);

        try {
            const careResponse = await this.authSessionRepository.getCareGuestToken();
            const careAuthSession = new AuthSession(
                careResponse.access_token,
                TOKEN_TYPE.GUEST,
                careResponse.expires_in
            );
            this.careAuthSession.updateAuthSession(careAuthSession);
        } catch (err) {
            console.error(err);
        }
    };
}
