import { AuthSession } from '../domain/entities/AuthSession';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { AUTHENTICATION_SOURCES, TOKEN_TYPE } from '../constants/constants';

export interface IAuthSession {
    token: string;
    expiration: number;
    source?: string;
    email?: string;
}

export class UpdateAuthSessionUseCase {
    private authSession: AuthSession;
    private careAuthSession: AuthSession;
    private data: IAuthSession;

    constructor(data: IAuthSession) {
        this.data = data;
        this.authSession = diContainer.get(serviceNames.authSession);
        this.careAuthSession = diContainer.get(serviceNames.careAuthSession);
    }

    public execute = () => {
        const authSession = new AuthSession(this.data.token, TOKEN_TYPE.USER, this.data.expiration);

        const targetAuthSession =
            this.data.source === AUTHENTICATION_SOURCES.CARE
                ? this.careAuthSession
                : this.authSession;

        targetAuthSession.updateAuthSession(authSession);
        targetAuthSession.setAuthSourceData(this.data);
    };
}
