import { action, computed, observable } from 'mobx';
import { FormStore, FormValidator } from 'elc-form';
import { ConfigStore } from '../../service-setup/ConfigStore';

export interface ISignUpFormStore {
    email: string;
}

export interface ISignUpFormStoreConfig {
    formValidator: FormValidator;
    configStore: ConfigStore;
}

export class SignUpFormStore extends FormStore<ISignUpFormStore> {
    public configStore: ConfigStore;
    @observable public isSignUpInProgress: boolean = false;

    constructor(signUpFormConfig: ISignUpFormStoreConfig) {
        super({
            initialValues: {
                email: ''
            },
            formValidator: signUpFormConfig.formValidator
        });
        this.configStore = signUpFormConfig.configStore;
    }

    @computed
    get isSignUpFormValid() {
        return this.isValid;
    }

    @action
    public updateSignUpProgress(isInProgress: boolean) {
        this.isSignUpInProgress = isInProgress;
    }
}
