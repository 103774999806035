import { observable, action, computed } from 'mobx';
import { TOKEN_TYPE } from '../../constants/constants';

export interface IAuthSession {
    token: string;
    source?: string;
    email?: string;
}

export class AuthSession {
    @observable public token: string | null;
    @observable public tokenType: string | null;
    @observable public authSourceData: IAuthSession;
    @observable public expiration: number | null;

    constructor(token: string | null, tokenType: string | null, expiration: number | null) {
        this.token = token;
        this.tokenType = tokenType;
        this.expiration = expiration;
    }

    @computed
    public get isAuthenticated() {
        const { token, tokenType } = this;

        return !!(token && tokenType === TOKEN_TYPE.USER);
    }

    @computed
    public get isAuthSessionAvailable() {
        return (
            this.token &&
            (this.tokenType === TOKEN_TYPE.USER || this.tokenType === TOKEN_TYPE.GUEST)
        );
    }

    @action
    private updateToken(token: string | null) {
        this.token = token;
    }

    @action
    private updateTokenType(tokenType: string | null) {
        this.tokenType = tokenType;
    }

    @action
    private updateExpiration(expiration: number | null) {
        this.expiration = expiration;
    }

    @action
    public updateAuthSession(authSession: AuthSession) {
        this.updateTokenType(authSession.tokenType);
        this.updateToken(authSession.token);
        this.updateExpiration(authSession.expiration);
    }

    @action
    public clearToken() {
        this.updateTokenType(null);
        this.updateToken(null);
        this.updateExpiration(null);
    }

    @action
    public setAuthSourceData(authSourceData: IAuthSession) {
        this.authSourceData = authSourceData;
    }
}
