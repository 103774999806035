import { Api, IConfig } from '@estee/elc-service';
import { IHttpTransport } from '@estee/elc-universal-utils';
import { ConfigStore } from '../service-setup/ConfigStore';
import { generateCareDomain } from '../internal/utils/care';

export interface ISignInResponse {
    token_type: string;
    access_token: string;
    expires_in: number;
    userId: string;
    userEmail: string;
    userRole: string;
    userCreationDate: string;
}

export interface ISignInApiSdkConfig extends IConfig {
    baseUrl: string;
    configStore: ConfigStore;
}

const { name, version } = __serviceInfo__;

export class SignInApiSdk extends Api {
    private authApiUrl: string;
    private baseUrl: string;
    private configStore: ConfigStore;

    constructor(config: ISignInApiSdkConfig) {
        super(config, name, version);
        this.authApiUrl = 'stardust-account-proxy-v0/oauth';

        this.baseUrl = config.baseUrl;
        this.configStore = config.configStore;
    }

    public get tokenUrl() {
        return `/${this.authApiUrl}/token`;
    }

    public guestTokenRequest = async (): Promise<ISignInResponse> => {
        const response = await this.httpTransport.post<ISignInResponse>({
            url: this.tokenUrl,
            body: {
                grant_type: 'client_credentials'
            }
        });

        return response.data;
    };

    public getGuestTokenIndependentFromApi = async (): Promise<ISignInResponse> => {
        const url = new URL(this.tokenUrl, this.baseUrl);

        const request: RequestInit = {
            method: 'POST',
            body: JSON.stringify({
                grant_type: 'client_credentials'
            }),
            headers: (this.httpTransport as IHttpTransport).getStaticGlobalHeaders() as {}
        };

        const response = await fetch(url.toString(), request);
        const responseJson = await response.json();

        return responseJson as ISignInResponse;
    };

    public getCareTokenIndependentFromApi = async (): Promise<ISignInResponse> => {
        const { env, clientId } = this.configStore.config;
        const queryParams = {};

        const encodedQueryParams = Object.keys(queryParams)
            .map(
                (k: keyof typeof queryParams) => `${k}=${encodeURIComponent(queryParams[k] || '')}`
            )
            .join('&');

        const url = `${generateCareDomain(window.location.origin, env)}/v0/oauth/token${
            Object.keys(queryParams).length ? `?${encodedQueryParams}` : ''
        }`;

        // @todo - remove this once we have support for token-less auth for guest mode apis
        const lowerEnvAuth = 'Y2xpbmlxdWUtZXMtZGctd2Vic2l0ZTpwcm94eUNsaWVudFNlY3JldA==';

        const encodeFormData = (data: object) => {
            let urlEncodedData = '';
            const urlEncodedDataPairs = [];

            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const userName in data) {
                urlEncodedDataPairs.push(
                    `${encodeURIComponent(userName)}=${encodeURIComponent(
                        data[userName as keyof typeof data] as string
                    )}`
                );
            }

            urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

            return urlEncodedData;
        };

        const formData = {
            grant_type: 'client_credentials',
            'business-unit': this.businessUnitId
        };

        const request: RequestInit = {
            method: 'POST',
            mode: 'cors',
            headers: {
                clientid: clientId,
                'content-type': 'application/x-www-form-urlencoded',
                authorization: `Basic ${lowerEnvAuth}`
            },
            body: encodeFormData(formData)
        };

        const response = await fetch(url, request);
        const responseJson = await response.json();

        return responseJson as ISignInResponse;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public signIn = async (signInData: any): Promise<ISignInResponse> => {
        const response = await this.httpTransport.post<ISignInResponse>({
            url: this.tokenUrl,
            body: signInData
        });

        return response.data;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public signOut = async (signOutData: any) => {
        return this.httpTransport.post({
            url: `/${this.authApiUrl}/revoke`,
            body: signOutData
        });
    };
}
