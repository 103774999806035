(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ElcServiceBus"), require("HypoContainer"), require("mobx"), require("mobx-react"), require("react"), require("react-dom"), require("styled"));
	else if(typeof define === 'function' && define.amd)
		define(["ElcServiceBus", "HypoContainer", "mobx", "mobx-react", "react", "react-dom", "styled"], factory);
	else if(typeof exports === 'object')
		exports["elc-service-session-mgmt"] = factory(require("ElcServiceBus"), require("HypoContainer"), require("mobx"), require("mobx-react"), require("react"), require("react-dom"), require("styled"));
	else
		root["elc-service-session-mgmt"] = factory(root["ElcServiceBus"], root["ElcRuntime"]["HypoContainer"], root["ElcRuntime"]["mobx"], root["ElcRuntime"]["mobxReact"], root["ElcRuntime"]["React"], root["ElcRuntime"]["ReactDom"], root["ElcRuntime"]["styled"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__estee_elc_service_bus__, __WEBPACK_EXTERNAL_MODULE_hypo_container__, __WEBPACK_EXTERNAL_MODULE_mobx__, __WEBPACK_EXTERNAL_MODULE_mobx_react__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__, __WEBPACK_EXTERNAL_MODULE_styled_components__) {
return 