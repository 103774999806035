import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { SignInRepository } from '../repositories/SignInRepository';

export class SetEmailUseCase {
    private signInRepository: SignInRepository;
    private email: string;

    constructor(email: string) {
        this.email = email;
        this.signInRepository = diContainer.get(serviceNames.signInRepository);
    }

    public execute = async () => {
        await this.signInRepository.ensureFormStore();
        this.signInRepository.setEmail(this.email);
    };
}
