import { ISignInApiSdkConfig, SignInApiSdk } from '../api/SignInApiSdk';
import { SignInRepository } from '../internal/repositories/SignInRepository';
import { ConfigStore } from './ConfigStore';
import { Container } from 'hypo-container';
import { ServiceBusController } from './ServiceBusController';
import { SessionManagementService } from './SessionManagementService';
import { getDecorators } from '@estee/elc-service';
import { root } from '@estee/elc-universal-utils';
import { AuthSession } from '../internal/domain/entities/AuthSession';
import { AuthSessionRepository } from '../internal/repositories/AuthSessionRepository';
import { GuestRepository } from '../internal/repositories/GuestRepository';
import { GuestUser } from '../internal/domain/entities/GuestUser';
import { SingleSignOnViewController } from '../internal/controllers/SingleSignOnViewController';
import { GuestFormViewController } from '../internal/controllers/GuestFormViewController';
import { NavLinkViewController } from '../internal/controllers/NavLinkViewController';
import { SignInFormViewController } from '../internal/controllers/SignInFormViewController';
import { SignUpFormViewController } from '../internal/controllers/SignUpFormViewController';
import { SignUpFormStore } from '../internal/stores/SignUpFormStore';
import { FormValidator } from 'elc-form';

export const diContainer = new Container();
export const serviceNames = {
    signInApiSdk: 'SIGN_IN_API_SDK',
    configStore: 'CONFIG_STORE',
    signInRepository: 'SIGN_IN_REPOSITORY',
    httpTransport: 'HTTP_TRANSPORT',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    sessionManagementService: 'SESSION_MANAGEMENT_SERVICE',
    authSession: 'AUTH_SESSION',
    careAuthSession: 'CARE_AUTH_SESSION',
    authSessionRepository: 'AUTH_SESSION_REPOSITORY',
    singleSignOnViewController: 'SINGLE_SIGN_ON_VIEW_CONTROLLER',
    signInFormViewController: 'SIGN_IN_FORM_VIEW_CONTROLLER',
    navLinkViewController: 'NAV_LINK_VIEW_CONTROLLER',
    guestFormViewController: 'GUEST_FORM_VIEW_CONTROLLER',
    guestRepository: 'GUEST_REPOSITORY',
    guestUser: 'GUEST_USER',
    signUpFormStore: 'SIGN_UP_FORM_STORE',
    signUpFormViewController: 'SIGN_UP_FORM_VIEW_CONTROLLER',
    formValidator: 'FORM_VALIDATOR',
    emailVerificationStore: 'EMAIL_VERIFICATION_STORE'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setConfig = (configJSON: any) => {
    diContainer.config = configJSON;
};

diContainer
    .register(
        serviceNames.configStore,
        diContainer.factory((c) => new ConfigStore(c.config))
    )
    .register(serviceNames.formValidator, (c) => new FormValidator())
    .register(serviceNames.httpTransport, (c) => {
        return root.HttpTransport;
    })
    .register(serviceNames.signInApiSdk, (c) => {
        const configStore: ConfigStore = c.get(serviceNames.configStore);
        const config: ISignInApiSdkConfig = {
            businessUnitId: configStore.config.businessUnitId,
            httpTransport: c.get(serviceNames.httpTransport),
            baseUrl: configStore.config.baseUrl,
            configStore
        };

        return new SignInApiSdk(config);
    })
    .register(
        serviceNames.signUpFormStore,
        (c) =>
            new SignUpFormStore({
                configStore: c.get(serviceNames.configStore),
                formValidator: c.get(serviceNames.formValidator)
            })
    )
    .register(
        serviceNames.serviceBusController,
        (c) =>
            new ServiceBusController({
                authSession: c.get(serviceNames.authSession),
                careAuthSession: c.get(serviceNames.careAuthSession),
                authSessionRepository: c.get(serviceNames.authSessionRepository),
                guestRepository: c.get(serviceNames.guestRepository),
                businessUnitId: c.get(serviceNames.configStore).config.businessUnitId
            })
    )
    .register(
        serviceNames.sessionManagementService,
        (c) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            new SessionManagementService((config: any) => {
                setConfig(config);

                return c.get(serviceNames.serviceBusController);
            })
    )
    .register(serviceNames.guestRepository, (c) => new GuestRepository())
    .register(serviceNames.guestUser, (c) => new GuestUser(''))
    .register(serviceNames.signInRepository, (c) => new SignInRepository())
    .register(
        serviceNames.authSessionRepository,
        (c) =>
            new AuthSessionRepository({
                signInApiSdk: c.get(serviceNames.signInApiSdk),
                authSession: c.get(serviceNames.authSession),
                careAuthSession: c.get(serviceNames.careAuthSession),
                httpTransport: c.get(serviceNames.httpTransport),
                configStore: c.get(serviceNames.configStore)
            })
    )
    .register(serviceNames.authSession, (c) => new AuthSession(null, null, null))
    .register(serviceNames.careAuthSession, () => new AuthSession(null, null, null))
    .register(
        serviceNames.singleSignOnViewController,
        diContainer.factory(
            (c) =>
                new SingleSignOnViewController({
                    guestRepository: c.get(serviceNames.guestRepository)
                })
        )
    )
    .register(
        serviceNames.navLinkViewController,
        diContainer.factory(
            (c) =>
                new NavLinkViewController({
                    careAuthSession: c.get(serviceNames.careAuthSession)
                })
        )
    )
    .register(
        serviceNames.guestFormViewController,
        diContainer.factory(
            (c) =>
                new GuestFormViewController({
                    guestRepository: c.get(serviceNames.guestRepository)
                })
        )
    )
    .register(
        serviceNames.signUpFormViewController,
        diContainer.factory(
            (c) =>
                new SignUpFormViewController({
                    signUpFormStore: c.get(serviceNames.signUpFormStore)
                })
        )
    )
    .register(
        serviceNames.signInFormViewController,
        diContainer.factory(
            (c) =>
                new SignInFormViewController({
                    signInRepository: c.get(serviceNames.signInRepository)
                })
        )
    );

const { lazyInject } = getDecorators(diContainer);

export { lazyInject };
