import { ViewController } from '@estee/elc-service';
import { observable, computed, action } from 'mobx';
import { GuestRepository } from '../repositories/GuestRepository';
import { LoadGuestUserUseCase } from '../use-cases/LoadGuestUserUseCase';
import { GuestUser } from '../domain/entities/GuestUser';

export interface ISingleSignOnViewControllerConfig {
    guestRepository: GuestRepository;
}

export class SingleSignOnViewController extends ViewController {
    @observable public showExistingAccountView: boolean = true;

    private guestRepository: GuestRepository;
    private guestUser: GuestUser;

    constructor(config: ISingleSignOnViewControllerConfig) {
        super();

        this.guestRepository = config.guestRepository;
    }

    public loaded = async () => {
        this.isLoading = true;

        const loadGuestUserUseCase = new LoadGuestUserUseCase(this.guestRepository);
        await loadGuestUserUseCase.execute();
        this.guestUser = await this.guestRepository.getGuestUser();

        this.isLoading = false;
    };

    @computed
    get data() {
        return {
            showExistingAccountView: this.showExistingAccountView,
            signUpView: this.guestUser && this.guestUser.signUpRequired
        };
    }

    @action
    public switchToExistingAccountView = () => {
        this.showExistingAccountView = true;
    };

    @action
    public switchToNewAccountView = () => {
        this.showExistingAccountView = false;
    };
}
