import { ViewController } from '@estee/elc-service';
import { SignInLinkUseCase } from '../use-cases/SignInLinkUseCase';
import { computed } from 'mobx';
import { AuthSession } from '../domain/entities/AuthSession';

export interface INavLinkViewControllerConfig {
    careAuthSession: AuthSession;
}

export class NavLinkViewController extends ViewController {
    private careAuthSession: AuthSession;

    constructor(config: INavLinkViewControllerConfig) {
        super();
        this.careAuthSession = config.careAuthSession;
    }

    @computed
    get data() {
        return {
            isAuthenticated: this.careAuthSession.isAuthenticated
        };
    }

    public onSignInLinkClick = () => {
        const useCase = new SignInLinkUseCase();
        useCase.execute();
    };
}
