import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { SignInRepository } from '../repositories/SignInRepository';
import { RequestCareAuthUseCase } from './RequestCareAuthUseCase';
import { query, serviceQueries } from '@estee/elc-service-bus';

export class SignInUseCase {
    private signInRepository: SignInRepository;

    constructor() {
        this.signInRepository = diContainer.get(serviceNames.signInRepository);
    }

    public execute = async () => {
        const email = (this.signInRepository.email || '').trim();

        const route = await query(serviceQueries.GET_CURRENT_ROUTE);
        const urlRedirectOnSuccess = route.queryParams.urlRedirectOnSuccess;

        const useCase = new RequestCareAuthUseCase({
            redirectUrl: urlRedirectOnSuccess,
            strategies:
                email && email.length
                    ? {
                          signin: {
                              email
                          }
                      }
                    : {}
        });
        await useCase.execute();
    };
}
