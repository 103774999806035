import { AuthSession } from '../domain/entities/AuthSession';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { SetGuestAuthSessionUseCase } from './SetGuestAuthSessionUseCase';
import { command, serviceCommands } from '@estee/elc-service-bus';
import { AuthSessionRepository } from '../repositories/AuthSessionRepository';

export class ClearAuthSessionUseCase {
    private authSession: AuthSession;
    private careAuthSession: AuthSession;
    private authSessionRepository: AuthSessionRepository;

    constructor(authSessionRepository: AuthSessionRepository) {
        this.authSession = diContainer.get(serviceNames.authSession);
        this.careAuthSession = diContainer.get(serviceNames.careAuthSession);
        this.authSessionRepository = authSessionRepository;
    }

    public execute = async () => {
        this.authSession.clearToken();
        this.careAuthSession.clearToken();
        void command(serviceCommands.CLEAR_USER_DATA);
        const setGuestAuthSessionUseCase = new SetGuestAuthSessionUseCase();
        await setGuestAuthSessionUseCase.execute();
        this.authSessionRepository.storeToken();
        this.authSessionRepository.storeCareToken();
    };
}
