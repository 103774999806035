import { AuthSession } from '../domain/entities/AuthSession';
import {
    AuthMode,
    AUTHORIZATION_HEADER_NAME,
    BLACKLISTED_AUTH_URLS,
    CARE_GUEST_SIGNIN_STATE_NAME,
    CARE_SIGNIN_DATA_NAME,
    CARE_SIGNIN_STATE_NAME,
    COOKIES_NAMES,
    TOKEN_TYPE
} from '../constants/constants';
import { BrowserStorage, IHttpTransport, IRequestTransformer } from '@estee/elc-universal-utils';
import { SignInApiSdk } from '../../api/SignInApiSdk';
import { ConfigStore } from '../../service-setup/ConfigStore';
import { IAccountConfig } from '../../service-setup/IAccountConfig';
import { IAuthRequestStateData } from '../domain/entities/IAuthRequestStateData';

export interface IAuthSessionRepositoryConfig {
    authSession: AuthSession;
    careAuthSession: AuthSession;
    signInApiSdk: SignInApiSdk;
    httpTransport: IHttpTransport;
    configStore: ConfigStore;
}

export class AuthSessionRepository {
    private authSession: AuthSession;
    private careAuthSession: AuthSession;
    private signInApiSdk: SignInApiSdk;
    private httpTransport: IHttpTransport;

    private requestTransformer: IRequestTransformer;

    public readonly enableCareConfig: boolean;

    private ensureAuthSessionPromise: Promise<void> | null = null;

    constructor(config: IAuthSessionRepositoryConfig) {
        this.authSession = config.authSession;
        this.careAuthSession = config.careAuthSession;

        this.signInApiSdk = config.signInApiSdk;
        this.httpTransport = config.httpTransport;

        const accountConfig: IAccountConfig = config.configStore.config.accountConfig || {};
        this.enableCareConfig = accountConfig.authMode === AuthMode.CARE;
    }

    public get authRequestState() {
        return BrowserStorage.getItem(CARE_SIGNIN_STATE_NAME, 'localStorage');
    }

    public get guestAuthRequestState() {
        return BrowserStorage.getItem(CARE_GUEST_SIGNIN_STATE_NAME, 'localStorage');
    }

    public setAuthRequestState(state: string) {
        BrowserStorage.setItem(CARE_SIGNIN_STATE_NAME, state, 'localStorage');
    }

    public setGuestAuthRequestState(state: string) {
        BrowserStorage.setItem(CARE_GUEST_SIGNIN_STATE_NAME, state, 'localStorage');
    }

    public setAuthRequestStateData(data: IAuthRequestStateData | null) {
        if (!data) {
            BrowserStorage.removeItem(CARE_SIGNIN_DATA_NAME);
        } else {
            BrowserStorage.setItem(CARE_SIGNIN_DATA_NAME, JSON.stringify(data), 'localStorage');
        }
    }

    public getAuthRequestStateData(): IAuthRequestStateData | null {
        const serialized = BrowserStorage.getItem(CARE_SIGNIN_DATA_NAME, 'localStorage');

        return serialized ? (JSON.parse(serialized) as IAuthRequestStateData) : null;
    }

    public storeToken = () => {
        // handle auth token cookie storage
        this.storeAuthSession(this.authSession, COOKIES_NAMES.AUTH_TOKEN, COOKIES_NAMES.AUTH_TYPE);
    };

    public storeCareToken = () => {
        this.storeAuthSession(
            this.careAuthSession,
            COOKIES_NAMES.CARE_AUTH_TOKEN,
            COOKIES_NAMES.CARE_AUTH_TYPE
        );
    };

    private get storedAuthSession() {
        const tokenCookie = BrowserStorage.getCookie(COOKIES_NAMES.AUTH_TOKEN);
        const tokenTypeCookie = BrowserStorage.getCookie(COOKIES_NAMES.AUTH_TYPE);

        return new AuthSession(tokenCookie, tokenTypeCookie, null);
    }

    private get storedCareAuthSession() {
        const tokenCookie = BrowserStorage.getCookie(COOKIES_NAMES.CARE_AUTH_TOKEN);
        const tokenTypeCookie = BrowserStorage.getCookie(COOKIES_NAMES.CARE_AUTH_TYPE);

        return new AuthSession(tokenCookie, tokenTypeCookie, null);
    }

    private storeAuthSession(
        authSession: AuthSession,
        cookieTokenName: string,
        cookieTypeName: string
    ) {
        const { token: authToken, tokenType, expiration } = authSession;

        if (!authToken) {
            BrowserStorage.eraseCookie(cookieTokenName);
            BrowserStorage.eraseCookie(cookieTypeName);
        } else {
            const storedToken = BrowserStorage.getCookie(cookieTokenName);
            const storedTokenType = BrowserStorage.getCookie(cookieTypeName);
            if (!storedToken || storedToken !== authToken || storedTokenType !== tokenType) {
                // update cookie if a new token is available
                BrowserStorage.setCookie(
                    cookieTokenName,
                    authToken,
                    new Date(Date.now() + (expiration || 0) * 1000)
                );
                BrowserStorage.setCookie(
                    cookieTypeName,
                    <string>tokenType,
                    new Date(Date.now() + (expiration || 0) * 1000)
                );
            }
        }
    }

    public getGuestToken = async () => {
        return this.signInApiSdk.getGuestTokenIndependentFromApi();
    };

    public getCareGuestToken = async () => {
        return this.signInApiSdk.getCareTokenIndependentFromApi();
    };

    public signOut = async () => {
        try {
            await this.signInApiSdk.signOut({ token: this.authSession.token });
        } catch (error) {
            throw error;
        }
    };

    public ensureAuthSession: () => Promise<void> = () => {
        let result: Promise<void> | null = this.ensureAuthSessionPromise;
        if (!result) {
            result = this.ensureAuthSessionPromise = this.doEnsureAuthSession();
        }

        return result as Promise<void>;
    };

    private doEnsureAuthSession = async () => {
        if (!this.requestTransformer) {
            this.requestTransformer = this.createRequestTransformer();
            this.httpTransport.addRequestTransformer(this.requestTransformer);
        }

        const { storedAuthSession } = this;
        try {
            if (storedAuthSession.isAuthSessionAvailable) {
                this.authSession.updateAuthSession(storedAuthSession);
            } else {
                const response = await this.signInApiSdk.getGuestTokenIndependentFromApi();
                this.setGuestAuthToken(response);
            }
        } catch (err) {
            console.error(err);
        }

        if (this.enableCareConfig) {
            const { storedCareAuthSession } = this;
            if (storedCareAuthSession.isAuthSessionAvailable) {
                this.careAuthSession.updateAuthSession(storedCareAuthSession);
            } else {
                try {
                    // get it from api
                    const response = await this.signInApiSdk.getCareTokenIndependentFromApi();
                    if (!response) {
                        throw new Error('no care token');
                    }
                    this.setGuestAuthToken(response);
                } catch (err) {
                    console.error(err);
                }
            }
        }

        this.ensureAuthSessionPromise = null;
    };

    private createRequestTransformer = (): IRequestTransformer => {
        return async (config) => {
            const useCareToken =
                this.enableCareConfig &&
                !BLACKLISTED_AUTH_URLS.some((url) => config.url.indexOf(url) >= 0);

            const session: AuthSession = useCareToken ? this.careAuthSession : this.authSession;

            if (
                (!useCareToken && !this.authSession.isAuthSessionAvailable) ||
                (useCareToken && !this.careAuthSession.isAuthSessionAvailable)
            ) {
                // ensure at least guest token
                await this.ensureAuthSession();
            }

            // use the guest auth token
            const newHeaders = new Headers(config.headers);
            newHeaders.set(AUTHORIZATION_HEADER_NAME, session.token as string);
            config.headers = newHeaders;
        };
    };

    private setGuestAuthToken = (response: { access_token: string; expires_in: number | null }) => {
        const newSession = new AuthSession(
            response.access_token,
            TOKEN_TYPE.GUEST,
            response.expires_in
        );
        this.authSession.updateAuthSession(newSession);
    };

    public getLegacyAuthToken = async () => {
        const storedAuthToken = this.authSession.token;
        if (storedAuthToken) {
            return storedAuthToken;
        }
        const response = await this.getGuestToken();
        this.setGuestAuthToken(response);

        return response.access_token;
    };
}
