export const dashifyOrigin = (origin: string) => {
    let url = origin
        .split('.')
        .map((part) => part.replace(/:[0-9]+/, ''))
        .join('-')
        .substr(0, 63)
        .replace(/[-]+$/, '');
    const protocolIndex = url.indexOf('://');
    url = `https://${url.substr(protocolIndex >= 0 ? protocolIndex + '://'.length : 0)}`;

    return url;
};

export const generateCareDomain = (source: string, env: string) => {
    return `${dashifyOrigin(source)}.${env}.care.elcdev.net`;
};
