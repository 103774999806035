import { emit, events, query, serviceQueries } from '@estee/elc-service-bus';

export class SignUpUseCase {
    private readonly email?: string;

    constructor(email?: string) {
        this.email = email;
    }

    public execute = async () => {
        const route = await query(serviceQueries.GET_CURRENT_ROUTE);
        const urlRedirectOnSuccess = route.queryParams.urlRedirectOnSuccess;

        emit(events.REQUEST_CARE_AUTH, {
            redirectUrl: urlRedirectOnSuccess,
            strategy: 'signup',
            strategies:
                this.email && this.email.length
                    ? {
                          signup: {
                              email: this.email
                          }
                      }
                    : {}
        });
    };
}
